<script>
import {defineComponent} from 'vue'
import store from "@/store";

export default defineComponent({
  name: "WelcomeView",
  computed: {
    store() {
      return store
    }
  }
})
</script>

<template>
  <h1>你好!欢迎光临我的小破站！</h1>
  <h2 v-if="store.state.user.userId===0">登录之后可以解锁更多功能哦！</h2>
</template>

<style scoped>

</style>
