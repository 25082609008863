import {createRouter, createWebHashHistory} from 'vue-router'
import WelcomeView from "@/views/WelcomeView.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue'),
    redirect: '/welcome',
    children:[//子路由
      {
        path:'/welcome',
        component:WelcomeView
      },
      {
        path:'/chooseEat',
        component:()=>import('../components/ChooseEat.vue')
      },
      {
        path: '/generateUnionLotto',
        component: () => import('../components/GenerateUnionLotto.vue')
      },
      {
        path:'/iconUpload',
        name: "iconUpload",
        component:()=>import('../components/UploadUserIcon.vue')
      },
      {
        path:'/passwordChange',
        name: "PasswordChange",
        component:()=>import('../components/PasswordChange.vue')
      },
      {
        path:'/userFileList',
        name: "UserFileList",
        component:()=>import('../components/UserFile.vue')
      },
      {
        path:'/uploadFile',
        name: "UploadFile",
        component:()=>import('../components/UploadFile.vue')
      },
      {
        path:'/reimbursementRegistration',
        name: "ReimbursementRegistration",
        component:()=>import('../components/ReimbursementRegistration.vue')
      },
      {
        path:'/costStatistics',
        name: "CostStatistics",
        component:()=>import('../components/CostStatistics.vue')
      },
      {
        path:'/excelHandle',
        name: "ExcelHandle",
        component:()=>import('../views/ExcelHandleView.vue')
      }
      ],
  },
  {
    path:'/login',
    name: "login",
    component:()=>import('../views/UserLogin.vue')
  }
  ,
  {
    path:'/register',
    name: "register",
    component:()=>import('../views/UserRegister.vue')
  }
  ,
  {
    path:'/forgetPassword',
    name: "forgetPassword",
    component:()=>import('../views/ForgetPassword.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
